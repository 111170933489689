.selectgroup-button {
  position: relative;
  display: block;
  min-width: $input-height;
  padding: $input-btn-padding-y 1rem;
  color: $text-muted;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  text-align: center;
  border: 1px solid $input-border-color;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
}

.selectgroup {
  display: inline-flex;
}

.selectgroup-item {
  position: relative;
  flex-grow: 1;
}

.selectgroup-pills {
  flex-wrap: wrap;
  align-items: flex-start;

  .selectgroup-item {
    flex-grow: 0;
    margin-right: .5rem;
  }

  .selectgroup-button {
    border-radius: 50px !important;
  }
}

.selectgroup-vertical {
  flex-direction: column;
}

.selectgroup-input:checked + .selectgroup-button {
  z-index: 1;
  color: $primary;
  background: mix(#fff, $primary, 90%);
  border-color: $primary;
}

.selectgroup-input:focus + .selectgroup-button {
  z-index: 2;
  color: $primary;
  border-color: $primary;
  box-shadow: $input-btn-focus-box-shadow;
}

.selectgroup-vertical > .selectgroup-item {
  &:not(:last-child) {
    margin-bottom: 0;
  }

  & + .selectgroup-item { // stylelint-disable-line scss/selector-no-redundant-nesting-selector
    margin-top: -1px;
    margin-left: 0;
  }

  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) .selectgroup-button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.selectgroup:not(.selectgroup-vertical) > .selectgroup-item {
  & + .selectgroup-item { // stylelint-disable-line scss/selector-no-redundant-nesting-selector
    margin-left: -1px;
  }

  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectgroup-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.selectgroup-button-icon {
  padding-right: .5rem;
  padding-left: .5rem;
  font-size: 1rem;
}
