.display-1 {
  i {
    font-size: 0.815em;
    vertical-align: baseline;
  }
}

.text-inherit { color: inherit !important; }
.text-default { color: $body-color !important; }
.text-muted-dark { color: $text-muted-dark !important; }

.leading-none { line-height: 1 !important; }