@import './tabler_variables';
@import 'bootstrap_overrides';
@import './account_statement';

// Bootstrap includes. We customise these as we don't want all of bootstrap.
// E.g. we don't need the code formatting from bootstrap.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/badge';

@import '~pickadate/lib/themes/default';
@import '~pickadate/lib/themes/default.date';
@import '~pickadate/lib/themes/default.time';

@import '~jasny-bootstrap/dist/css/jasny-bootstrap';

@import 'tabler/dashboard';
@import 'payments';

.card {
  border: 0;
  box-shadow: 0 7px 14px 0 rgba(0, 16, 79, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}

.TODO { // stylelint-disable-line
  background: repeating-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 10px,
    rgba(255, 255, 0, 0.1) 10px,
    rgba(255, 255, 0, 0.1) 20px
  );
}

/* Design playground stylings */
code, pre, pre .tag {
  font-size: .8rem;
}

pre .tag {
  display: inline;
  padding: 0;
  background-color: transparent; /*rgb(245, 242, 240);*/
  user-select: auto;
}

.sidebar {
  a {
    margin-right: 1rem;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
      margin-right: 0;
    }
  }

  .active {
    font-weight: bold;
  }
}

.example {
  // A code snippet with an example rendering below
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  font-size: px2rem(15px);
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 3px 3px 0 0;

  pre[class*=language-] { // stylelint-disable-line
    margin-top: -1rem;
    margin-right: -1.5rem;
    margin-bottom: 1rem;
    margin-left: -1.5rem;
    padding: 1rem 1.5rem;
  }
}

.snippet {
  // A code snippet without an example rendering below
  margin-bottom: 1rem;
  padding: 1rem 1.5rem 0;
  font-size: px2rem(15px);
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 3px 3px 0 0;

  pre[class*=language-] { // stylelint-disable-line
    margin-top: -1rem;
    margin-right: -1.5rem;
    margin-bottom: 0;
    margin-left: -1.5rem;
    padding: 1rem 1.5rem;
  }
}

.design-guide-container {
  h1 {
    padding-bottom: 1rem;
    line-height: 1.5rem;
  }

  .sidebar {
    margin-top: -1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-y: scroll;

    ul {
      display: none;
    }

    a {
      display: inline-block;
    }

    @media (min-width: map-get($grid-breakpoints, 'md') + 1) {
      position: sticky;
      top: 0;
      height: calc(100vh - 4.225rem);
      padding-bottom: 0;

      a, ul { // stylelint-disable-line
        display: block;
      }
    }
  }
}

code.inline { // stylelint-disable-line
  font-size: 13px;
}

.no-bullet {
  // Hides the bullet point on a list item
  list-style: none;
}

.badge {
  font-weight: normal;
  vertical-align: text-top;
}

.card-table th {
  color: #343a40;
}

.close {
  font-size: 1rem;
  line-height: 1.5;
  transition: .3s color;
}

.close::before {
  font-family: 'feather';
  content: '\f215';
}

.highlight-shadow {
  border: 0;
  box-shadow: 0 0 14px 0 rgba(0, 16, 79, 0.1), 0 0 6px 0 rgba(0, 0, 0, .07);
}

.strikethrough {
  text-decoration: line-through;
}

.d-inline {
  display: inline;
}

.panel {
  background-color: #fff;
}

.fileinput-preview {
  width: 200px;
  height: 150px;
  margin-bottom: 10px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.fileinput-new .fileinput-preview {
  display: none;
}

.has-error p {
  color: #d00;
}

textarea.form-control { // stylelint-disable-line
  font-family: $font-family-base;
}

.flex-btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-btn-container button {
  flex-basis: 60%;
  margin-bottom: 5px;
}

.modal-card {
  /* stylelint-disable */
  @extend .card;
  /* stylelint-enable */
  width: auto;
  max-width: 800px;
}
