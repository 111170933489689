$svg-payment-icons: ('americanexpress', 'dankort', 'dinersclub', 'discover', 'jcb', 'maestro', 'mastercard', 'unionpay', 'visa');
$png-payment-icons: ('interpayment', 'uatp');

.payment {
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
  font-style: normal;
  vertical-align: bottom;
  background: no-repeat center/100% 100%;
  border-radius: 2px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);
}

@each $payment in $svg-payment-icons {
  .payment-#{$payment} {
    background-image: url('../assets/images/payments/' + $payment + '.svg');
  }
}

@each $payment in $png-payment-icons {
  .payment-#{$payment} {
    background-image: url('../assets/images/payments/' + $payment + '.png');
  }
}
