@font-face {
  font-weight: normal;
  font-family: 'feather';
  font-style: normal;
  src: url('../assets/fonts/feather/feather.woff2?902ff421100c826ac4fee9f7fd922287') format('woff2'),
    url('../assets/fonts/feather/feather.woff?902ff421100c826ac4fee9f7fd922287') format('woff'),
    url('../assets/fonts/feather/feather.ttf?902ff421100c826ac4fee9f7fd922287') format('truetype'),
    url('../assets/fonts/feather/feather.svg?902ff421100c826ac4fee9f7fd922287#feather') format('svg');
}

.fe {
  speak: none;
  font-weight: normal;
  font-family: 'feather' !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-fe-activity: '\f101';

.fe-activity::before {
  content: $icon-fe-activity;
}

$icon-fe-airplay: '\f102';

.fe-airplay::before {
  content: $icon-fe-airplay;
}

$icon-fe-alert-circle: '\f103';

.fe-alert-circle::before {
  content: $icon-fe-alert-circle;
}

$icon-fe-alert-octagon: '\f104';

.fe-alert-octagon::before {
  content: $icon-fe-alert-octagon;
}

$icon-fe-alert-triangle: '\f105';

.fe-alert-triangle::before {
  content: $icon-fe-alert-triangle;
}

$icon-fe-align-center: '\f106';

.fe-align-center::before {
  content: $icon-fe-align-center;
}

$icon-fe-align-justify: '\f107';

.fe-align-justify::before {
  content: $icon-fe-align-justify;
}

$icon-fe-align-left: '\f108';

.fe-align-left::before {
  content: $icon-fe-align-left;
}

$icon-fe-align-right: '\f109';

.fe-align-right::before {
  content: $icon-fe-align-right;
}

$icon-fe-anchor: '\f10a';

.fe-anchor::before {
  content: $icon-fe-anchor;
}

$icon-fe-aperture: '\f10b';

.fe-aperture::before {
  content: $icon-fe-aperture;
}

$icon-fe-archive: '\f10c';

.fe-archive::before {
  content: $icon-fe-archive;
}

$icon-fe-arrow-down-circle: '\f10d';

.fe-arrow-down-circle::before {
  content: $icon-fe-arrow-down-circle;
}

$icon-fe-arrow-down-left: '\f10e';

.fe-arrow-down-left::before {
  content: $icon-fe-arrow-down-left;
}

$icon-fe-arrow-down-right: '\f10f';

.fe-arrow-down-right::before {
  content: $icon-fe-arrow-down-right;
}

$icon-fe-arrow-down: '\f110';

.fe-arrow-down::before {
  content: $icon-fe-arrow-down;
}

$icon-fe-arrow-left-circle: '\f111';

.fe-arrow-left-circle::before {
  content: $icon-fe-arrow-left-circle;
}

$icon-fe-arrow-left: '\f112';

.fe-arrow-left::before {
  content: $icon-fe-arrow-left;
}

$icon-fe-arrow-right-circle: '\f113';

.fe-arrow-right-circle::before {
  content: $icon-fe-arrow-right-circle;
}

$icon-fe-arrow-right: '\f114';

.fe-arrow-right::before {
  content: $icon-fe-arrow-right;
}

$icon-fe-arrow-up-circle: '\f115';

.fe-arrow-up-circle::before {
  content: $icon-fe-arrow-up-circle;
}

$icon-fe-arrow-up-left: '\f116';

.fe-arrow-up-left::before {
  content: $icon-fe-arrow-up-left;
}

$icon-fe-arrow-up-right: '\f117';

.fe-arrow-up-right::before {
  content: $icon-fe-arrow-up-right;
}

$icon-fe-arrow-up: '\f118';

.fe-arrow-up::before {
  content: $icon-fe-arrow-up;
}

$icon-fe-at-sign: '\f119';

.fe-at-sign::before {
  content: $icon-fe-at-sign;
}

$icon-fe-award: '\f11a';

.fe-award::before {
  content: $icon-fe-award;
}

$icon-fe-bar-chart-2: '\f11b';

.fe-bar-chart-2::before {
  content: $icon-fe-bar-chart-2;
}

$icon-fe-bar-chart: '\f11c';

.fe-bar-chart::before {
  content: $icon-fe-bar-chart;
}

$icon-fe-battery-charging: '\f11d';

.fe-battery-charging::before {
  content: $icon-fe-battery-charging;
}

$icon-fe-battery: '\f11e';

.fe-battery::before {
  content: $icon-fe-battery;
}

$icon-fe-bell-off: '\f11f';

.fe-bell-off::before {
  content: $icon-fe-bell-off;
}

$icon-fe-bell: '\f120';

.fe-bell::before {
  content: $icon-fe-bell;
}

$icon-fe-bluetooth: '\f121';

.fe-bluetooth::before {
  content: $icon-fe-bluetooth;
}

$icon-fe-bold: '\f122';

.fe-bold::before {
  content: $icon-fe-bold;
}

$icon-fe-book-open: '\f123';

.fe-book-open::before {
  content: $icon-fe-book-open;
}

$icon-fe-book: '\f124';

.fe-book::before {
  content: $icon-fe-book;
}

$icon-fe-bookmark: '\f125';

.fe-bookmark::before {
  content: $icon-fe-bookmark;
}

$icon-fe-box: '\f126';

.fe-box::before {
  content: $icon-fe-box;
}

$icon-fe-briefcase: '\f127';

.fe-briefcase::before {
  content: $icon-fe-briefcase;
}

$icon-fe-calendar: '\f128';

.fe-calendar::before {
  content: $icon-fe-calendar;
}

$icon-fe-camera-off: '\f129';

.fe-camera-off::before {
  content: $icon-fe-camera-off;
}

$icon-fe-camera: '\f12a';

.fe-camera::before {
  content: $icon-fe-camera;
}

$icon-fe-cast: '\f12b';

.fe-cast::before {
  content: $icon-fe-cast;
}

$icon-fe-check-circle: '\f12c';

.fe-check-circle::before {
  content: $icon-fe-check-circle;
}

$icon-fe-check-square: '\f12d';

.fe-check-square::before {
  content: $icon-fe-check-square;
}

$icon-fe-check: '\f12e';

.fe-check::before {
  content: $icon-fe-check;
}

$icon-fe-chevron-down: '\f12f';

.fe-chevron-down::before {
  content: $icon-fe-chevron-down;
}

$icon-fe-chevron-left: '\f130';

.fe-chevron-left::before {
  content: $icon-fe-chevron-left;
}

$icon-fe-chevron-right: '\f131';

.fe-chevron-right::before {
  content: $icon-fe-chevron-right;
}

$icon-fe-chevron-up: '\f132';

.fe-chevron-up::before {
  content: $icon-fe-chevron-up;
}

$icon-fe-chevrons-down: '\f133';

.fe-chevrons-down::before {
  content: $icon-fe-chevrons-down;
}

$icon-fe-chevrons-left: '\f134';

.fe-chevrons-left::before {
  content: $icon-fe-chevrons-left;
}

$icon-fe-chevrons-right: '\f135';

.fe-chevrons-right::before {
  content: $icon-fe-chevrons-right;
}

$icon-fe-chevrons-up: '\f136';

.fe-chevrons-up::before {
  content: $icon-fe-chevrons-up;
}

$icon-fe-chrome: '\f137';

.fe-chrome::before {
  content: $icon-fe-chrome;
}

$icon-fe-circle: '\f138';

.fe-circle::before {
  content: $icon-fe-circle;
}

$icon-fe-clipboard: '\f139';

.fe-clipboard::before {
  content: $icon-fe-clipboard;
}

$icon-fe-clock: '\f13a';

.fe-clock::before {
  content: $icon-fe-clock;
}

$icon-fe-cloud-drizzle: '\f13b';

.fe-cloud-drizzle::before {
  content: $icon-fe-cloud-drizzle;
}

$icon-fe-cloud-lightning: '\f13c';

.fe-cloud-lightning::before {
  content: $icon-fe-cloud-lightning;
}

$icon-fe-cloud-off: '\f13d';

.fe-cloud-off::before {
  content: $icon-fe-cloud-off;
}

$icon-fe-cloud-rain: '\f13e';

.fe-cloud-rain::before {
  content: $icon-fe-cloud-rain;
}

$icon-fe-cloud-snow: '\f13f';

.fe-cloud-snow::before {
  content: $icon-fe-cloud-snow;
}

$icon-fe-cloud: '\f140';

.fe-cloud::before {
  content: $icon-fe-cloud;
}

$icon-fe-code: '\f141';

.fe-code::before {
  content: $icon-fe-code;
}

$icon-fe-codepen: '\f142';

.fe-codepen::before {
  content: $icon-fe-codepen;
}

$icon-fe-codesandbox: '\f143';

.fe-codesandbox::before {
  content: $icon-fe-codesandbox;
}

$icon-fe-coffee: '\f144';

.fe-coffee::before {
  content: $icon-fe-coffee;
}

$icon-fe-columns: '\f145';

.fe-columns::before {
  content: $icon-fe-columns;
}

$icon-fe-command: '\f146';

.fe-command::before {
  content: $icon-fe-command;
}

$icon-fe-compass: '\f147';

.fe-compass::before {
  content: $icon-fe-compass;
}

$icon-fe-copy: '\f148';

.fe-copy::before {
  content: $icon-fe-copy;
}

$icon-fe-corner-down-left: '\f149';

.fe-corner-down-left::before {
  content: $icon-fe-corner-down-left;
}

$icon-fe-corner-down-right: '\f14a';

.fe-corner-down-right::before {
  content: $icon-fe-corner-down-right;
}

$icon-fe-corner-left-down: '\f14b';

.fe-corner-left-down::before {
  content: $icon-fe-corner-left-down;
}

$icon-fe-corner-left-up: '\f14c';

.fe-corner-left-up::before {
  content: $icon-fe-corner-left-up;
}

$icon-fe-corner-right-down: '\f14d';

.fe-corner-right-down::before {
  content: $icon-fe-corner-right-down;
}

$icon-fe-corner-right-up: '\f14e';

.fe-corner-right-up::before {
  content: $icon-fe-corner-right-up;
}

$icon-fe-corner-up-left: '\f14f';

.fe-corner-up-left::before {
  content: $icon-fe-corner-up-left;
}

$icon-fe-corner-up-right: '\f150';

.fe-corner-up-right::before {
  content: $icon-fe-corner-up-right;
}

$icon-fe-cpu: '\f151';

.fe-cpu::before {
  content: $icon-fe-cpu;
}

$icon-fe-credit-card: '\f152';

.fe-credit-card::before {
  content: $icon-fe-credit-card;
}

$icon-fe-crop: '\f153';

.fe-crop::before {
  content: $icon-fe-crop;
}

$icon-fe-crosshair: '\f154';

.fe-crosshair::before {
  content: $icon-fe-crosshair;
}

$icon-fe-database: '\f155';

.fe-database::before {
  content: $icon-fe-database;
}

$icon-fe-delete: '\f156';

.fe-delete::before {
  content: $icon-fe-delete;
}

$icon-fe-disc: '\f157';

.fe-disc::before {
  content: $icon-fe-disc;
}

$icon-fe-dollar-sign: '\f158';

.fe-dollar-sign::before {
  content: $icon-fe-dollar-sign;
}

$icon-fe-download-cloud: '\f159';

.fe-download-cloud::before {
  content: $icon-fe-download-cloud;
}

$icon-fe-download: '\f15a';

.fe-download::before {
  content: $icon-fe-download;
}

$icon-fe-droplet: '\f15b';

.fe-droplet::before {
  content: $icon-fe-droplet;
}

$icon-fe-edit-2: '\f15c';

.fe-edit-2::before {
  content: $icon-fe-edit-2;
}

$icon-fe-edit-3: '\f15d';

.fe-edit-3::before {
  content: $icon-fe-edit-3;
}

$icon-fe-edit: '\f15e';

.fe-edit::before {
  content: $icon-fe-edit;
}

$icon-fe-external-link: '\f15f';

.fe-external-link::before {
  content: $icon-fe-external-link;
}

$icon-fe-eye-off: '\f160';

.fe-eye-off::before {
  content: $icon-fe-eye-off;
}

$icon-fe-eye: '\f161';

.fe-eye::before {
  content: $icon-fe-eye;
}

$icon-fe-facebook: '\f162';

.fe-facebook::before {
  content: $icon-fe-facebook;
}

$icon-fe-fast-forward: '\f163';

.fe-fast-forward::before {
  content: $icon-fe-fast-forward;
}

$icon-fe-feather: '\f164';

.fe-feather::before {
  content: $icon-fe-feather;
}

$icon-fe-figma: '\f165';

.fe-figma::before {
  content: $icon-fe-figma;
}

$icon-fe-file-minus: '\f166';

.fe-file-minus::before {
  content: $icon-fe-file-minus;
}

$icon-fe-file-plus: '\f167';

.fe-file-plus::before {
  content: $icon-fe-file-plus;
}

$icon-fe-file-text: '\f168';

.fe-file-text::before {
  content: $icon-fe-file-text;
}

$icon-fe-file: '\f169';

.fe-file::before {
  content: $icon-fe-file;
}

$icon-fe-film: '\f16a';

.fe-film::before {
  content: $icon-fe-film;
}

$icon-fe-filter: '\f16b';

.fe-filter::before {
  content: $icon-fe-filter;
}

$icon-fe-flag: '\f16c';

.fe-flag::before {
  content: $icon-fe-flag;
}

$icon-fe-folder-minus: '\f16d';

.fe-folder-minus::before {
  content: $icon-fe-folder-minus;
}

$icon-fe-folder-plus: '\f16e';

.fe-folder-plus::before {
  content: $icon-fe-folder-plus;
}

$icon-fe-folder: '\f16f';

.fe-folder::before {
  content: $icon-fe-folder;
}

$icon-fe-framer: '\f170';

.fe-framer::before {
  content: $icon-fe-framer;
}

$icon-fe-frown: '\f171';

.fe-frown::before {
  content: $icon-fe-frown;
}

$icon-fe-gift: '\f172';

.fe-gift::before {
  content: $icon-fe-gift;
}

$icon-fe-git-branch: '\f173';

.fe-git-branch::before {
  content: $icon-fe-git-branch;
}

$icon-fe-git-commit: '\f174';

.fe-git-commit::before {
  content: $icon-fe-git-commit;
}

$icon-fe-git-merge: '\f175';

.fe-git-merge::before {
  content: $icon-fe-git-merge;
}

$icon-fe-git-pull-request: '\f176';

.fe-git-pull-request::before {
  content: $icon-fe-git-pull-request;
}

$icon-fe-github: '\f177';

.fe-github::before {
  content: $icon-fe-github;
}

$icon-fe-gitlab: '\f178';

.fe-gitlab::before {
  content: $icon-fe-gitlab;
}

$icon-fe-globe: '\f179';

.fe-globe::before {
  content: $icon-fe-globe;
}

$icon-fe-grid: '\f17a';

.fe-grid::before {
  content: $icon-fe-grid;
}

$icon-fe-hard-drive: '\f17b';

.fe-hard-drive::before {
  content: $icon-fe-hard-drive;
}

$icon-fe-hash: '\f17c';

.fe-hash::before {
  content: $icon-fe-hash;
}

$icon-fe-headphones: '\f17d';

.fe-headphones::before {
  content: $icon-fe-headphones;
}

$icon-fe-heart: '\f17e';

.fe-heart::before {
  content: $icon-fe-heart;
}

$icon-fe-help-circle: '\f17f';

.fe-help-circle::before {
  content: $icon-fe-help-circle;
}

$icon-fe-hexagon: '\f180';

.fe-hexagon::before {
  content: $icon-fe-hexagon;
}

$icon-fe-home: '\f181';

.fe-home::before {
  content: $icon-fe-home;
}

$icon-fe-image: '\f182';

.fe-image::before {
  content: $icon-fe-image;
}

$icon-fe-inbox: '\f183';

.fe-inbox::before {
  content: $icon-fe-inbox;
}

$icon-fe-info: '\f184';

.fe-info::before {
  content: $icon-fe-info;
}

$icon-fe-instagram: '\f185';

.fe-instagram::before {
  content: $icon-fe-instagram;
}

$icon-fe-italic: '\f186';

.fe-italic::before {
  content: $icon-fe-italic;
}

$icon-fe-key: '\f187';

.fe-key::before {
  content: $icon-fe-key;
}

$icon-fe-layers: '\f188';

.fe-layers::before {
  content: $icon-fe-layers;
}

$icon-fe-layout: '\f189';

.fe-layout::before {
  content: $icon-fe-layout;
}

$icon-fe-life-buoy: '\f18a';

.fe-life-buoy::before {
  content: $icon-fe-life-buoy;
}

$icon-fe-link-2: '\f18b';

.fe-link-2::before {
  content: $icon-fe-link-2;
}

$icon-fe-link: '\f18c';

.fe-link::before {
  content: $icon-fe-link;
}

$icon-fe-linkedin: '\f18d';

.fe-linkedin::before {
  content: $icon-fe-linkedin;
}

$icon-fe-list: '\f18e';

.fe-list::before {
  content: $icon-fe-list;
}

$icon-fe-loader: '\f18f';

.fe-loader::before {
  content: $icon-fe-loader;
}

$icon-fe-lock: '\f190';

.fe-lock::before {
  content: $icon-fe-lock;
}

$icon-fe-log-in: '\f191';

.fe-log-in::before {
  content: $icon-fe-log-in;
}

$icon-fe-log-out: '\f192';

.fe-log-out::before {
  content: $icon-fe-log-out;
}

$icon-fe-mail: '\f193';

.fe-mail::before {
  content: $icon-fe-mail;
}

$icon-fe-map-pin: '\f194';

.fe-map-pin::before {
  content: $icon-fe-map-pin;
}

$icon-fe-map: '\f195';

.fe-map::before {
  content: $icon-fe-map;
}

$icon-fe-maximize-2: '\f196';

.fe-maximize-2::before {
  content: $icon-fe-maximize-2;
}

$icon-fe-maximize: '\f197';

.fe-maximize::before {
  content: $icon-fe-maximize;
}

$icon-fe-meh: '\f198';

.fe-meh::before {
  content: $icon-fe-meh;
}

$icon-fe-menu: '\f199';

.fe-menu::before {
  content: $icon-fe-menu;
}

$icon-fe-message-circle: '\f19a';

.fe-message-circle::before {
  content: $icon-fe-message-circle;
}

$icon-fe-message-square: '\f19b';

.fe-message-square::before {
  content: $icon-fe-message-square;
}

$icon-fe-mic-off: '\f19c';

.fe-mic-off::before {
  content: $icon-fe-mic-off;
}

$icon-fe-mic: '\f19d';

.fe-mic::before {
  content: $icon-fe-mic;
}

$icon-fe-minimize-2: '\f19e';

.fe-minimize-2::before {
  content: $icon-fe-minimize-2;
}

$icon-fe-minimize: '\f19f';

.fe-minimize::before {
  content: $icon-fe-minimize;
}

$icon-fe-minus-circle: '\f1a0';

.fe-minus-circle::before {
  content: $icon-fe-minus-circle;
}

$icon-fe-minus-square: '\f1a1';

.fe-minus-square::before {
  content: $icon-fe-minus-square;
}

$icon-fe-minus: '\f1a2';

.fe-minus::before {
  content: $icon-fe-minus;
}

$icon-fe-monitor: '\f1a3';

.fe-monitor::before {
  content: $icon-fe-monitor;
}

$icon-fe-moon: '\f1a4';

.fe-moon::before {
  content: $icon-fe-moon;
}

$icon-fe-more-horizontal: '\f1a5';

.fe-more-horizontal::before {
  content: $icon-fe-more-horizontal;
}

$icon-fe-more-vertical: '\f1a6';

.fe-more-vertical::before {
  content: $icon-fe-more-vertical;
}

$icon-fe-mouse-pointer: '\f1a7';

.fe-mouse-pointer::before {
  content: $icon-fe-mouse-pointer;
}

$icon-fe-move: '\f1a8';

.fe-move::before {
  content: $icon-fe-move;
}

$icon-fe-music: '\f1a9';

.fe-music::before {
  content: $icon-fe-music;
}

$icon-fe-navigation-2: '\f1aa';

.fe-navigation-2::before {
  content: $icon-fe-navigation-2;
}

$icon-fe-navigation: '\f1ab';

.fe-navigation::before {
  content: $icon-fe-navigation;
}

$icon-fe-octagon: '\f1ac';

.fe-octagon::before {
  content: $icon-fe-octagon;
}

$icon-fe-package: '\f1ad';

.fe-package::before {
  content: $icon-fe-package;
}

$icon-fe-paperclip: '\f1ae';

.fe-paperclip::before {
  content: $icon-fe-paperclip;
}

$icon-fe-pause-circle: '\f1af';

.fe-pause-circle::before {
  content: $icon-fe-pause-circle;
}

$icon-fe-pause: '\f1b0';

.fe-pause::before {
  content: $icon-fe-pause;
}

$icon-fe-pen-tool: '\f1b1';

.fe-pen-tool::before {
  content: $icon-fe-pen-tool;
}

$icon-fe-percent: '\f1b2';

.fe-percent::before {
  content: $icon-fe-percent;
}

$icon-fe-phone-call: '\f1b3';

.fe-phone-call::before {
  content: $icon-fe-phone-call;
}

$icon-fe-phone-forwarded: '\f1b4';

.fe-phone-forwarded::before {
  content: $icon-fe-phone-forwarded;
}

$icon-fe-phone-incoming: '\f1b5';

.fe-phone-incoming::before {
  content: $icon-fe-phone-incoming;
}

$icon-fe-phone-missed: '\f1b6';

.fe-phone-missed::before {
  content: $icon-fe-phone-missed;
}

$icon-fe-phone-off: '\f1b7';

.fe-phone-off::before {
  content: $icon-fe-phone-off;
}

$icon-fe-phone-outgoing: '\f1b8';

.fe-phone-outgoing::before {
  content: $icon-fe-phone-outgoing;
}

$icon-fe-phone: '\f1b9';

.fe-phone::before {
  content: $icon-fe-phone;
}

$icon-fe-pie-chart: '\f1ba';

.fe-pie-chart::before {
  content: $icon-fe-pie-chart;
}

$icon-fe-play-circle: '\f1bb';

.fe-play-circle::before {
  content: $icon-fe-play-circle;
}

$icon-fe-play: '\f1bc';

.fe-play::before {
  content: $icon-fe-play;
}

$icon-fe-plus-circle: '\f1bd';

.fe-plus-circle::before {
  content: $icon-fe-plus-circle;
}

$icon-fe-plus-square: '\f1be';

.fe-plus-square::before {
  content: $icon-fe-plus-square;
}

$icon-fe-plus: '\f1bf';

.fe-plus::before {
  content: $icon-fe-plus;
}

$icon-fe-pocket: '\f1c0';

.fe-pocket::before {
  content: $icon-fe-pocket;
}

$icon-fe-power: '\f1c1';

.fe-power::before {
  content: $icon-fe-power;
}

$icon-fe-printer: '\f1c2';

.fe-printer::before {
  content: $icon-fe-printer;
}

$icon-fe-radio: '\f1c3';

.fe-radio::before {
  content: $icon-fe-radio;
}

$icon-fe-refresh-ccw: '\f1c4';

.fe-refresh-ccw::before {
  content: $icon-fe-refresh-ccw;
}

$icon-fe-refresh-cw: '\f1c5';

.fe-refresh-cw::before {
  content: $icon-fe-refresh-cw;
}

$icon-fe-repeat: '\f1c6';

.fe-repeat::before {
  content: $icon-fe-repeat;
}

$icon-fe-rewind: '\f1c7';

.fe-rewind::before {
  content: $icon-fe-rewind;
}

$icon-fe-rotate-ccw: '\f1c8';

.fe-rotate-ccw::before {
  content: $icon-fe-rotate-ccw;
}

$icon-fe-rotate-cw: '\f1c9';

.fe-rotate-cw::before {
  content: $icon-fe-rotate-cw;
}

$icon-fe-rss: '\f1ca';

.fe-rss::before {
  content: $icon-fe-rss;
}

$icon-fe-save: '\f1cb';

.fe-save::before {
  content: $icon-fe-save;
}

$icon-fe-scissors: '\f1cc';

.fe-scissors::before {
  content: $icon-fe-scissors;
}

$icon-fe-search: '\f1cd';

.fe-search::before {
  content: $icon-fe-search;
}

$icon-fe-send: '\f1ce';

.fe-send::before {
  content: $icon-fe-send;
}

$icon-fe-server: '\f1cf';

.fe-server::before {
  content: $icon-fe-server;
}

$icon-fe-settings: '\f1d0';

.fe-settings::before {
  content: $icon-fe-settings;
}

$icon-fe-share-2: '\f1d1';

.fe-share-2::before {
  content: $icon-fe-share-2;
}

$icon-fe-share: '\f1d2';

.fe-share::before {
  content: $icon-fe-share;
}

$icon-fe-shield-off: '\f1d3';

.fe-shield-off::before {
  content: $icon-fe-shield-off;
}

$icon-fe-shield: '\f1d4';

.fe-shield::before {
  content: $icon-fe-shield;
}

$icon-fe-shopping-bag: '\f1d5';

.fe-shopping-bag::before {
  content: $icon-fe-shopping-bag;
}

$icon-fe-shopping-cart: '\f1d6';

.fe-shopping-cart::before {
  content: $icon-fe-shopping-cart;
}

$icon-fe-shuffle: '\f1d7';

.fe-shuffle::before {
  content: $icon-fe-shuffle;
}

$icon-fe-sidebar: '\f1d8';

.fe-sidebar::before {
  content: $icon-fe-sidebar;
}

$icon-fe-skip-back: '\f1d9';

.fe-skip-back::before {
  content: $icon-fe-skip-back;
}

$icon-fe-skip-forward: '\f1da';

.fe-skip-forward::before {
  content: $icon-fe-skip-forward;
}

$icon-fe-slack: '\f1db';

.fe-slack::before {
  content: $icon-fe-slack;
}

$icon-fe-slash: '\f1dc';

.fe-slash::before {
  content: $icon-fe-slash;
}

$icon-fe-sliders: '\f1dd';

.fe-sliders::before {
  content: $icon-fe-sliders;
}

$icon-fe-smartphone: '\f1de';

.fe-smartphone::before {
  content: $icon-fe-smartphone;
}

$icon-fe-smile: '\f1df';

.fe-smile::before {
  content: $icon-fe-smile;
}

$icon-fe-speaker: '\f1e0';

.fe-speaker::before {
  content: $icon-fe-speaker;
}

$icon-fe-square: '\f1e1';

.fe-square::before {
  content: $icon-fe-square;
}

$icon-fe-star: '\f1e2';

.fe-star::before {
  content: $icon-fe-star;
}

$icon-fe-stop-circle: '\f1e3';

.fe-stop-circle::before {
  content: $icon-fe-stop-circle;
}

$icon-fe-sun: '\f1e4';

.fe-sun::before {
  content: $icon-fe-sun;
}

$icon-fe-sunrise: '\f1e5';

.fe-sunrise::before {
  content: $icon-fe-sunrise;
}

$icon-fe-sunset: '\f1e6';

.fe-sunset::before {
  content: $icon-fe-sunset;
}

$icon-fe-tablet: '\f1e7';

.fe-tablet::before {
  content: $icon-fe-tablet;
}

$icon-fe-tag: '\f1e8';

.fe-tag::before {
  content: $icon-fe-tag;
}

$icon-fe-target: '\f1e9';

.fe-target::before {
  content: $icon-fe-target;
}

$icon-fe-terminal: '\f1ea';

.fe-terminal::before {
  content: $icon-fe-terminal;
}

$icon-fe-thermometer: '\f1eb';

.fe-thermometer::before {
  content: $icon-fe-thermometer;
}

$icon-fe-thumbs-down: '\f1ec';

.fe-thumbs-down::before {
  content: $icon-fe-thumbs-down;
}

$icon-fe-thumbs-up: '\f1ed';

.fe-thumbs-up::before {
  content: $icon-fe-thumbs-up;
}

$icon-fe-toggle-left: '\f1ee';

.fe-toggle-left::before {
  content: $icon-fe-toggle-left;
}

$icon-fe-toggle-right: '\f1ef';

.fe-toggle-right::before {
  content: $icon-fe-toggle-right;
}

$icon-fe-tool: '\f1f0';

.fe-tool::before {
  content: $icon-fe-tool;
}

$icon-fe-trash-2: '\f1f1';

.fe-trash-2::before {
  content: $icon-fe-trash-2;
}

$icon-fe-trash: '\f1f2';

.fe-trash::before {
  content: $icon-fe-trash;
}

$icon-fe-trello: '\f1f3';

.fe-trello::before {
  content: $icon-fe-trello;
}

$icon-fe-trending-down: '\f1f4';

.fe-trending-down::before {
  content: $icon-fe-trending-down;
}

$icon-fe-trending-up: '\f1f5';

.fe-trending-up::before {
  content: $icon-fe-trending-up;
}

$icon-fe-triangle: '\f1f6';

.fe-triangle::before {
  content: $icon-fe-triangle;
}

$icon-fe-truck: '\f1f7';

.fe-truck::before {
  content: $icon-fe-truck;
}

$icon-fe-tv: '\f1f8';

.fe-tv::before {
  content: $icon-fe-tv;
}

$icon-fe-twitch: '\f1f9';

.fe-twitch::before {
  content: $icon-fe-twitch;
}

$icon-fe-twitter: '\f1fa';

.fe-twitter::before {
  content: $icon-fe-twitter;
}

$icon-fe-type: '\f1fb';

.fe-type::before {
  content: $icon-fe-type;
}

$icon-fe-umbrella: '\f1fc';

.fe-umbrella::before {
  content: $icon-fe-umbrella;
}

$icon-fe-underline: '\f1fd';

.fe-underline::before {
  content: $icon-fe-underline;
}

$icon-fe-unlock: '\f1fe';

.fe-unlock::before {
  content: $icon-fe-unlock;
}

$icon-fe-upload-cloud: '\f1ff';

.fe-upload-cloud::before {
  content: $icon-fe-upload-cloud;
}

$icon-fe-upload: '\f200';

.fe-upload::before {
  content: $icon-fe-upload;
}

$icon-fe-user-check: '\f201';

.fe-user-check::before {
  content: $icon-fe-user-check;
}

$icon-fe-user-minus: '\f202';

.fe-user-minus::before {
  content: $icon-fe-user-minus;
}

$icon-fe-user-plus: '\f203';

.fe-user-plus::before {
  content: $icon-fe-user-plus;
}

$icon-fe-user-x: '\f204';

.fe-user-x::before {
  content: $icon-fe-user-x;
}

$icon-fe-user: '\f205';

.fe-user::before {
  content: $icon-fe-user;
}

$icon-fe-users: '\f206';

.fe-users::before {
  content: $icon-fe-users;
}

$icon-fe-video-off: '\f207';

.fe-video-off::before {
  content: $icon-fe-video-off;
}

$icon-fe-video: '\f208';

.fe-video::before {
  content: $icon-fe-video;
}

$icon-fe-voicemail: '\f209';

.fe-voicemail::before {
  content: $icon-fe-voicemail;
}

$icon-fe-volume-1: '\f20a';

.fe-volume-1::before {
  content: $icon-fe-volume-1;
}

$icon-fe-volume-2: '\f20b';

.fe-volume-2::before {
  content: $icon-fe-volume-2;
}

$icon-fe-volume-x: '\f20c';

.fe-volume-x::before {
  content: $icon-fe-volume-x;
}

$icon-fe-volume: '\f20d';

.fe-volume::before {
  content: $icon-fe-volume;
}

$icon-fe-watch: '\f20e';

.fe-watch::before {
  content: $icon-fe-watch;
}

$icon-fe-wifi-off: '\f20f';

.fe-wifi-off::before {
  content: $icon-fe-wifi-off;
}

$icon-fe-wifi: '\f210';

.fe-wifi::before {
  content: $icon-fe-wifi;
}

$icon-fe-wind: '\f211';

.fe-wind::before {
  content: $icon-fe-wind;
}

$icon-fe-x-circle: '\f212';

.fe-x-circle::before {
  content: $icon-fe-x-circle;
}

$icon-fe-x-octagon: '\f213';

.fe-x-octagon::before {
  content: $icon-fe-x-octagon;
}

$icon-fe-x-square: '\f214';

.fe-x-square::before {
  content: $icon-fe-x-square;
}

$icon-fe-x: '\f215';

.fe-x::before {
  content: $icon-fe-x;
}

$icon-fe-youtube: '\f216';

.fe-youtube::before {
  content: $icon-fe-youtube;
}

$icon-fe-zap-off: '\f217';

.fe-zap-off::before {
  content: $icon-fe-zap-off;
}

$icon-fe-zap: '\f218';

.fe-zap::before {
  content: $icon-fe-zap;
}

$icon-fe-zoom-in: '\f219';

.fe-zoom-in::before {
  content: $icon-fe-zoom-in;
}

$icon-fe-zoom-out: '\f21a';

.fe-zoom-out::before {
  content: $icon-fe-zoom-out;
}

