.card {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  .card {
    box-shadow: none;
  }

  @media print {
    border: 0;
    box-shadow: none;
  }
}

.card-body {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: $card-spacer-y $card-spacer-x;

  & + & {
    border-top: 1px solid $border-color;
  }

  > :last-child {
    margin-bottom: 0;
  }

  @media print {
    padding: 0;
  }
}

.card-body-scrollable {
  overflow: auto;
}

.card-footer,
.card-bottom {
  padding: 1rem $card-spacer-x;
  background: none;
}

.card-footer {
  color: $text-muted-dark;
  border-top: 1px solid $border-color;
}

.card-header {
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  padding: .5rem $card-spacer-x;
  background: none;

  .card-title {
    margin-bottom: 0;
  }

  &.border-0 + .card-body {
    padding-top: 0;
  }

  @media print {
    display: none;
  }
}

.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .4);
}

.card-title {
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: $h4-font-size;
  line-height: 1.2;

  a {
    color: inherit;
  }

  &:only-child {
    margin-bottom: 0;
  }
}

.card-title small,
.card-subtitle {
  display: block;
  margin: -.75rem 0 1rem;
  color: $text-muted;
  font-weight: 400;
  font-size: px2rem(14px);
  line-height: 1.1;
}

.card-table {
  margin-bottom: 0;

  tr {
    &:first-child {
      // stylelint-disable max-nesting-depth
      td,
      th {
        border-top: 0;
      }
    }

    td,
    th {
      &:first-child {
        padding-left: $card-spacer-x;
      }

      &:last-child {
        padding-right: $card-spacer-x;
      }
    }
    // stylelint-enable max-nesting-depth
  }

  .card-body + & {
    border-top: 1px solid $border-color;
  }
}

.card-profile {
  .card-header {
    height: 9rem;
    background-size: cover;
  }
}

.card-profile-img {
  max-width: 6rem;
  margin-top: -5rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card-link {
  + .card-link {
    margin-left: 1rem;
  }
}

.card-list-group {
  .card-body + & {
    border-top: 1px solid $border-color;
  }

  .list-group-item {
    padding-right: $card-spacer-x;

    padding-left: $card-spacer-x;
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      border-top: 0;
    }
  }
}

.card-header-tabs {
  margin: -1.25rem 0;
  line-height: 2rem;
  border-bottom: 0;

  .nav-item {
    margin-bottom: 1px;
  }
}

.card-header-pills {
  margin: -.75rem 0;
}

.card-aside {
  flex-direction: row;
}

.card-aside-column {
  flex: 0 0 30%;
  width: 30%;
  min-width: 5rem;
  background: no-repeat center/cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-value {
  display: flex;
  align-items: center;
  height: 3.4rem;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.4rem;

  i {
    vertical-align: middle;
  }
}

.card-chart-bg {
  position: relative;
  z-index: 1;
  height: 4rem;
  margin-top: -1rem;
  overflow: hidden;
}

.card-options {
  display: flex;
  align-self: center;
  order: 100;
  margin-right: -.5rem;
  margin-left: auto;
  color: $text-muted;

  a:not(.btn) {
    display: inline-block;
    min-width: 1rem;
    margin-left: .5rem;
    color: $text-muted;

    &:hover {
      color: $text-muted-dark;
      text-decoration: none;
    }

    i {
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

/*
Card options
 */
.card-collapsed {
  >:not(.card-header):not(.card-status) {
    display: none;
  }
}

.card-options-collapse {
  i::before {
    font-size: 1.5rem;
    content: '\f132';
  }

  &.collapsed {
    i::before {
      content: '\f12f';
    }
  }
}

.card-options-fullscreen {
  i::before {
    .card-fullscreen & {
      content: '\f196';
    }
  }
}

.card-options-remove {
  .card-fullscreen & {
    display: none;
  }
}

/*
Card maps
 */
.card-map {
  height: 15rem;
  background: $gray-200;
}

.card-map-placeholder {
  background: no-repeat center;
}

/**
Card tabs
 */
.card-tabs {
  display: flex;
}

.card-tabs-bottom {
  .card-tabs-item {
    border: 0;
    border-top: 1px solid $border-color;

    &.active {
      border-top-color: #fff;
    }
  }
}

.card-tabs-item {
  display: block;
  flex: 1 1 auto;
  padding: 1rem $card-spacer-x;
  overflow: hidden;
  color: inherit;
  border-bottom: 1px solid $border-color;

  @at-root a#{&} {
    background: #fafbfc;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      z-index: 1;
    }

    &.active {
      background: #fff;
      border-bottom-color: #fff;
    }
  }

  & + & {
    border-left: 1px solid $border-color;
  }
}


/**
Card status
 */
.card-status {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  height: 3px;
  background: $border-color;
  border-radius: $card-border-radius $card-border-radius 0 0;
}

.card-status-left {
  right: auto;
  bottom: 0;
  width: 3px;
  height: auto;
  border-radius: $card-border-radius 0 0 $card-border-radius;
}

/**
Card icon
 */
.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}

/**
Card fullscreen
 */
.card-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 0;
}

/**
Card alert
 */
.card-alert {
  margin: -1px -1px 0;
  border-radius: 0;
}

.card-category {
  margin: 0 0 .5rem;
  font-weight: 600;
  font-size: px2rem(14px);
  letter-spacing: .05em;
  text-align: center;
  text-transform: uppercase;
}