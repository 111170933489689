textarea {
  &[cols] { // stylelint-disable-line selector-no-qualifying-type
    height: auto;
  }
}


.form-group {
  display: block;
}

.form-label {
  display: block;
  margin-bottom: .375rem;
  font-weight: 600;
  font-size: px2rem(14px);
}

.form-label-small {
  float: right;
  font-weight: 400;
  font-size: $small-font-size;
}

.form-footer {
  margin-top: 2rem;
}

.custom-control {
  user-select: none;
}

.custom-controls-stacked {
  .custom-control {
    margin-bottom: .25rem;
  }
}

.custom-control-label {
  vertical-align: middle;

  &::before {
    background-color: #fff;
    background-size: .5rem;
    border: 1px solid $input-border-color;
  }
}

.custom-control-description {
  line-height: 1.5rem;
}

.input-group-prepend,
.input-group-append,
.input-group-btn {
  font-size: px2rem(15px);

  >.btn {
    height: 100%;
    border-color: $border-color;
  }
}

.input-group-prepend > .input-group-text {
  border-right: 0;
}

.input-group-append > .input-group-text {
  border-left: 0;
}

/**
Icon input
 */
.input-icon {
  position: relative;

  .form-control:not(:last-child) {
    padding-right: 2.5rem;
  }

  .form-control:not(:first-of-type) {
    padding-left: 2.5rem;
  }
}

.input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  color: $text-muted;
  pointer-events: none;

  &:last-child {
    right: 0;
    left: auto;
  }
}


.form-fieldset {
  margin-bottom: 1rem;
  padding: 1rem;
  background: $gray-100;
  border: 1px solid $gray-200;
  border-radius: 3px;
}

.form-required {
  color: $red;

  &::before {
    content: ' ';
  }
}

.state-valid {
  padding-right: 2rem;
  background: str-replace(url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$green}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>"), "#", "%23") no-repeat center right .5rem/1rem; // stylelint-disable-line
}

.state-invalid {
  padding-right: 2rem;
  background: str-replace(url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$red}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"), "#", "%23") no-repeat center right .5rem/1rem; // stylelint-disable-line
}

.form-help {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  color: $text-muted;
  font-size: px2rem(12px);
  line-height: 1rem;
  text-align: center;
  text-decoration: none;
  background: $gray-100;
  border-radius: 50%;
  cursor: pointer;
  transition: .3s background-color, .3s color;
  user-select: none;

  &:hover,
  &[aria-describedby] {
    color: #fff;
    background: $primary;
  }
}