.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  body.fixed-header & { // stylelint-disable-line selector-no-qualifying-type
    padding-top: $header-height;
  }
}

.page-main {
  flex: 1 1 auto;
}

.page-content {
  margin: .75rem 0;
  @include media-breakpoint-up(md) {
    margin: 1.5rem 0;
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1.5rem 0;
}

.page-title {
  margin: 0;
  font-weight: 400;
  font-size: $h3-font-size;
  line-height: 2.5rem;
}

.page-title-icon {
  color: $text-muted;
  font-size: 1.25rem;
}

.page-subtitle {
  margin-left: 2rem;
  color: $text-muted-dark;
  font-size: px2rem(13px);

  a {
    color: inherit;
  }
}

.page-options {
  margin-left: auto;
}

.page-breadcrumb {
  flex-basis: 100%;
}

.page-description {
  margin: .25rem 0 0;
  color: $text-muted-dark;

  a {
    color: inherit;
  }
}

.page-single {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}



.content-heading {
  margin: 2rem 0 1.5rem;
  font-weight: 400;
  font-size: px2rem(20px);
  line-height: 1.25;

  &:first-child {
    margin-top: 0;
  }
}