.lead {
  line-height: 1.4;
}

a {
  text-decoration-skip-ink: auto;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  a {
    color: inherit;
  }
}

strong,
b {
  font-weight: 600;
}

p,
ul,
ol,
blockquote {
  margin-bottom: 1em;
}

blockquote {
  padding-left: 2rem;
  color: $text-muted-dark;
  font-style: italic;
  border-left: 2px solid $border-color;

  p {
    margin-bottom: 1rem;
  }

  cite {
    display: block;
    text-align: right;

    &::before {
      content: '— ';
    }
  }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

img {
  max-width: 100%;
}

.text-italic {
  font-style: italic;
}

.list-heading {
  margin-bottom: 5px;
  font-weight: 400;
}