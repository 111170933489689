.nav-link,
.nav-item {
  display: flex;
  align-items: center;
  min-width: 2rem;
  padding: 0 .75rem;
  cursor: pointer;
  transition: .3s color;
  user-select: none;

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1rem;
    padding: .2rem .25rem;
  }
}

.nav-tabs {
  margin: 0 -.75rem;
  color: $text-muted;
  user-select: none;

  .nav-link {
    margin-bottom: -1px;
    padding: 1rem 0;
    color: inherit;
    font-weight: 400;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: .3s border-color;

    &:hover:not(.disabled) {
      color: $text-muted-dark;
      border-color: $text-muted-dark;
    }

    &.active {
      color: $blue;
      background: transparent;
      border-color: $blue;
    }

    &.disabled {
      cursor: default;
      opacity: .4;
      pointer-events: none;
    }
  }

  .nav-item {
    position: relative;
    margin-bottom: 0;

    i {
      display: inline-block;
      width: px2rem(14px);
      margin-right: .25rem;
      font-size: px2rem(14px);
      line-height: 1;
      vertical-align: baseline;
    }

    &:hover .nav-submenu {
      display: block;
    }
  }

  .nav-submenu {
    position: absolute;
    z-index: 10;
    display: none;
    min-width: 10rem;
    background: #fff;
    border: 1px solid $border-color;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .nav-submenu .nav-item {
    display: block;
    margin: 0 !important;
    padding: .5rem 1rem;
    color: $text-muted;
    cursor: pointer;
    transition: .3s background;

    &.active {
      color: $link-color;
    }

    &:hover {
      color: $text-muted-dark;
      text-decoration: none;
      background: rgba(0, 0, 0, .024);
    }
  }
}
