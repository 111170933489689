// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

//Fonts
$font-family-base: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace: Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-sm: .875rem;

$line-height-base: 1.5;

$h1-font-size: 2rem; //32px
$h2-font-size: 1.75rem; //28px
$h3-font-size: 1.5rem; //24px
$h4-font-size: 1.125rem; //18px
$h5-font-size: 1rem; //16px
$h6-font-size: .875rem; //14px

$small-font-size: 87.5%;

$headings-margin-bottom: .66em;
$headings-font-family: inherit;
$headings-font-weight: 600;
$headings-line-height: 1.1;
$headings-color: inherit;

$display1-size: 4.5rem;
$display2-size: 4rem;
$display3-size: 3.5rem;
$display4-size: 3rem;

// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue: #467fcf;
$azure: #45aaf2;
$red: #cd201f;
$orange: #fd9644;
$yellow: #f1c40f;
$green: #5eba00;


$text-muted: #9aa0ac;
$text-muted-dark: #6e7687;

$white: #fff;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $azure;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$colors: (

);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
);

$body-bg: #f5f7fb;
$body-color: $gray-700;

// Code
$code-font-size: 85%;
$code-color: inherit;

$kbd-color: #fff;
$kbd-bg: $gray-800;

$pre-color: $gray-900;

// Yiq
$yiq-contrasted-threshold: 190;
$yiq-text-dark: $body-color;

// Buttons
$input-line-height: (24/15);
$btn-line-height: (24/13);

$input-line-height-sm: (16/14);
$btn-line-height-sm: (16/12);

$input-line-height-lg: (26/18);
$btn-line-height-lg: (26/16);

$input-btn-focus-width: 2px;

$input-disabled-bg: $gray-100;

$input-focus-border-color: #1991eb;
$custom-select-focus-border-color: #1991eb;

// Borders
$border-width: 1px;
//$border-color: #dee3eb;
$border-color: rgba(0, 40, 100, .12);
$border-color-dark: rgba(0, 40, 100, .24);

// Inputs
$input-bg: #fff;
$input-height: 2.375rem;

$input-color: $gray-700;
$input-border-color: $border-color;
$input-border-width: 1px;
$input-box-shadow: inset 0 1px 1px rgba(#000, .075);

$input-group-addon-border-color: $input-border-color;
$input-group-addon-bg: #fbfbfc;

$input-placeholder-color: $gray-500;

// Grid
$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

// Header
$header-bg: #0667d0;
$header-color: #fff;
$header-height: 4.5rem;

// Cards
$card-border-color: $border-color;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

// Alerts
$alert-link-font-weight: 600;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer * .75,
  4: $spacer * 1,
  5: $spacer * 1.5,
  6: $spacer * 2,
  7: $spacer * 3,
  8: $spacer * 4,
  9: $spacer * 6
);

// Footer
$footer-bg: #fff;

// Dropdowns
$dropdown-border-color: $border-color;
$dropdown-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

// Z-index
$zindex-overlay: 900;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Components
$border-radius: 3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

// Tables
$table-accent-bg: rgba(0, 0, 0, .02);
$table-hover-bg: rgba(0, 0, 0, .04);

$custom-select-padding-y: .5rem;
$custom-select-padding-x: .75rem;

$custom-select-indicator-color: #999;
$custom-select-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E");

// Hr
$hr-border-color: $border-color;

// Thumbnails
$thumbnail-bg: #fff;

// Navs
$nav-tabs-link-active-bg: transparent;