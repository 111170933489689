.btn {
  min-width: px2rem(38px);
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0;
  cursor: pointer;

  i {
    font-size: 1rem;
    vertical-align: -2px;
  }
}

.btn-icon {
  padding-right: .5rem;
  padding-left: .5rem;
  text-align: center;
}

.btn-secondary {
  @include button-variant(#fff, $input-border-color, #f6f6f6);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-pill {
  padding-right: 1.5em;
  padding-left: 1.5em;
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

@each $vendor, $color in $colors {
  .btn-#{$vendor} {
    @include button-variant($color, $color);
  }
}

.btn-option {
  color: $text-muted;
  background: transparent;

  &:hover {
    color: $text-muted-dark;
  }

  &:focus {
    color: $text-muted-dark;
    box-shadow: none;
  }
}

.btn-sm {
  min-width: px2rem(26px);
  font-size: px2rem(12px);
}

.btn-lg {
  min-width: px2rem(44px);
  font-weight: 400;
  font-size: px2rem(16px);
}

.btn-list {
  margin-bottom: -.5rem;
  font-size: 0;

  >.btn,
  >.dropdown {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.btn-loading {
  position: relative;
  color: transparent !important;
  pointer-events: none;

  &::after {
    position: absolute !important;
    top: calc(50% - (1.4em / 2));
    left: calc(50% - (1.4em / 2));
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 2px solid #fff;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-radius: 50%;
    transform-origin: center;
    animation: loader 500ms infinite linear;
    content: '';
  }

  &.btn-sm::after {
    top: calc(50% - (1em / 2));
    left: calc(50% - (1em / 2));
    width: 1em;
    height: 1em;
  }

  &.btn-secondary::after {
    border-color: $yiq-text-dark;
  }
}
