.dropdown {
  display: inline-block;
}

.dropdown-menu {
  min-width: 12rem;
  box-shadow: $dropdown-box-shadow;
}

.dropdown-item {
  color: $text-muted-dark;
}

.dropdown-menu-arrow {
  &::before {
    position: absolute;
    top: -6px;
    left: 12px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $border-color;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-left: 5px solid transparent;
    content: '';
  }

  &::after {
    position: absolute;
    top: -5px;
    left: 12px;
    display: inline-block;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    border-left: 5px solid transparent;
    content: '';
  }

  &.dropdown-menu-right {
    &::before,
    &::after {
      right: 12px;
      left: auto;
    }
  }
}

.dropdown-toggle {
  cursor: pointer;
  user-select: none;

  &::after {
    vertical-align: 0.155em;
  }

  &:empty::after {
    margin-left: 0;
  }
}

.dropdown-icon {
  display: inline-block;
  width: 1em;
  margin-right: .5rem;
  margin-left: -.5rem;
  color: $text-muted;
  text-align: center;
  vertical-align: -1px;
}