.header {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background: #fff;
  border-bottom: 1px solid $border-color;

  &.dark {
    background: #0c4382;
  }

  body.fixed-header & { // stylelint-disable-line selector-no-qualifying-type
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
  }

  @media print {
    display: none;
  }

  .dropdown-menu {
    margin-top: .75rem;
  }
}

.nav-unread {
  position: absolute;
  top: .25rem;
  right: .25rem;
  width: .5rem;
  height: .5rem;
  background: $red;
  border-radius: 50%;
}

.header-brand {
  margin-right: 1rem;
  padding: 0;
  color: inherit;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2rem;
  white-space: nowrap;
  transition: .3s opacity;

  &:hover {
    color: inherit;
    text-decoration: none;
    opacity: .8;
  }
}

.header-brand-img {
  width: auto;
  height: 2rem;
  margin-right: .5rem;
  line-height: 2rem;
  vertical-align: bottom;
}

.header-avatar {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: bottom;
  border-radius: 50%;
}

.header-btn {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;

  &.has-new {
    position: relative;

    &::before {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 6px;
      height: 6px;
      background: $red;
      border-radius: 50%;
      content: '';
    }
  }
}

.header-toggler {
  position: relative;
  width: 2rem;
  height: 2rem;
  color: $text-muted;

  &:hover {
    color: $text-muted-dark;
  }
}

.header-toggler-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 2px;
  margin: -2px 0 0 -.5rem;
  color: inherit;
  background: currentColor;
  border-radius: 3px;
  box-shadow: 0 5px currentColor, 0 -5px currentColor;
}