.explanatory-note {
  padding-right: 5px;
  padding-left: 5px;
  overflow: hidden;
  border: 0;
  outline: none;
}

.explanatory-note:hover, .explanatory-note:focus {
  color: #0c4382;
}