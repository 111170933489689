.table {
  thead {
    th {
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-top: 0;
      border-bottom-width: 1px;
    }
  }

  th {
    color: $text-muted;
    font-weight: 400;
    font-size: $h6-font-size;
    text-transform: uppercase;
  }
}

.table-md {
  th,
  td {
    padding: .5rem;
  }
}

.table-vcenter {
  td,
  th {
    vertical-align: middle;
  }
}

.table-center {
  td,
  th {
    text-align: center;
  }
}

td.table-center { // stylelint-disable-line
  text-align: center;
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background: transparent;
  }

  tbody tr:nth-of-type(even) {
    background-color: $table-accent-bg;
  }
}

.table-calendar {
  margin: 0 0 .75rem;

  td,
  th {
    width: (100%/7);
    padding: 0 !important;
    line-height: 2.5rem;
    text-align: center;
    border: 0;
  }

  td {
    border-top: 0;
  }
}

.table-calendar-link {
  position: relative;
  display: inline-block;
  min-width: calc(2rem + 2px);
  color: $body-color;
  font-weight: 600;
  line-height: 2rem;
  background: $gray-100;
  border-radius: 3px;
  transition: .3s background, .3s color;

  &::before {
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 4px;
    height: 4px;
    background: $primary;
    border-radius: 50px;
    content: '';
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    background: $primary;
    transition: .3s background;

    &::before {
      background: #fff;
    }
  }
}


.table-header {
  cursor: pointer;
  transition: .3s color;

  &:hover {
    color: $body-color !important;
  }

  &::after {
    display: inline-block;
    margin-left: .5rem;
    font-size: .75rem;
    font-family: FontAwesome;
    content: '\f0dc';
  }
}

.table-header-asc {
  color: $body-color !important;

  &::after {
    content: '\f0de';
  }
}

.table-header-desc {
  color: $body-color !important;

  &::after {
    content: '\f0dd';
  }
}