$font-size-sm: $font-size-base * .75;
$tooltip-font-size: 0.875rem;
$line-height-sm: 1.33333333;
$small-font-size: 87.5%;
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$input-font-size: 0.9375rem;
$input-height: initial;

// This is used because by default in bootstrap 4, is-valid and is-invalid add
// little icons to the input, and this is not wanted.
$enable-validation-icons: false;
