html {
  height: 100%;
  font-size: 16px;
  direction: ltr;
}

body {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none; // stylelint-disable-line
  -ms-touch-action: manipulation; // stylelint-disable-line
  touch-action: manipulation;
  -webkit-font-feature-settings: 'liga' 0; // stylelint-disable-line
  font-feature-settings: 'liga' 0;

  @media print {
    background: none;
  }
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: .3s background;
}

body *::-webkit-scrollbar-thumb {
  background: $gray-400;
}

body *:hover::-webkit-scrollbar-thumb {
  background: $gray-500;
}
