@keyframes status-pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .32;
  }
}

.status-icon {
  display: inline-block;
  width: px2rem(8px);
  height: px2rem(8px);
  margin-right: .375rem;
  vertical-align: middle;
  background: currentColor;
  border-radius: 50%;
  transform: translateY(-1px);
  content: '';
}

.status-animated {
  animation: 1s status-pulse infinite ease;
}